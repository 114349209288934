
/**
 * @name: 设备管理-机械臂设备信息管理
 * @author: itmobai
 * @date: 2024-07-18 14:45
 * @description： 设备管理-机械臂设备信息管理
 * @update: 2024-07-18 14:45
 */
import { Vue, Component } from "vue-property-decorator"
import {
  deviceArmCreateApi,
  deviceArmModifyStatusApi,
  deviceArmQueryApi,
  deviceArmAllocationApi,
  deviceArmDetailListApi,
  deviceArmExportApi
} from '@/apis/device/arm-list'
import {
  IDeviceArm, IDeviceArmAllocation, IDeviceArmDetailParams,
  IDeviceArmParams,
} from '@/apis/device/arm-list/types'
import type { ICrudOption } from "@/types/m-ui-crud"
import {deepCopy,exportFile} from "@/utils/common";
import Qrcode from 'qrcodejs2'
import {
  agentListApi
} from '@/apis/agent/list'
import { WEB_HOST } from "@/config";
import {IAgent} from "@/apis/agent/list/types";

@Component({})
export default class deviceArmList extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IDeviceArm[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IDeviceArmParams = {
    page: 1,
    limit: 10,
    allocationTimeSort: null
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menuWidth: 100,
    column: [
      {
        label: '',
        type: 'selection',
        width: 50,
        align: 'center',
        selectable: function (row: IDeviceArm, index: number) {
          if (row.status !== 1) {
            return false
          }
          return true
        }
      },
      {
        "label": "设备编号",
        "prop": "deviceSn",
        "align": "left",
        "width": 180,
        "search": true
      },
      {
        "label": "所属代理商",
        "prop": "agentName",
        "align": "center",
        "search": true,
        "overHidden": true,
        width: 150
      },
      {
        "label": "业务合作方绑定数量",
        "prop": "partnerNum",
        width: 150,
        align: 'center',
        slot: true
      },
      {
        "label": "消费单价(元)",
        "prop": "price",
        "align": "center",
        "width": 120
      },
      {
        "label": "投放地址",
        "prop": "address",
        "align": "center",
        "width": 150,
        "overHidden": true,
        slot: true
      },
      {
        "label": "二维码",
        "prop": "qrCode",
        "align": "center",
        "width": 110,
        slot: true
      },
      {
        "label": "状态",
        "prop": "status",
        "align": "center",
        "width": 100,
        "type": "select",
        "search": true,
        dicData: [
          {
            label: '待分配',
            value: 1
          },
          {
            label: '待激活',
            value: 2
          },
          {
            label: '已激活',
            value: 3
          },
          {
            label: '暂停使用',
            value: 4
          }
        ]
      },
      {
        "label": "创建时间",
        "prop": "addTime",
        "align": "center",
        "width": 150,
        "type": "daterange",
        "overHidden": true,
        search: true
      },
      {
        "label": "分配时间",
        "prop": "allocationTime",
        "align": "center",
        "width": 150,
        "type": "daterange",
        "overHidden": true,
        search: true,
        searchSlot: true,
        // @ts-ignore
        sortable: true
      },
      {
        "label": "激活时间",
        "prop": "activateTime",
        "align": "center",
        "width": 150,
        "type": "daterange",
        "overHidden": true,
        search: true
      },
      {
        "label": "开关",
        "prop": "switchgear",
        "align": "center",
        slot: true,
        width: 120
      }
    ]
  }

  addDialog = false

  addForm: any = {}

  addColumn: any = [
    {
      label: '数量',
      prop: 'deviceNum',
      rules: [
        {
          required: true,
          message: '数量不能为空',
          trigger: 'blur'
        },
        {
          validator: (rule: any, value: string, cb: Function) => {
            if (!/^\d+$/.test(value) || value == '0' || parseFloat(value) === 0) {
              return cb(new Error('数量只能是正整数'))
            }
            if (parseInt(value) > 9999) {
              return cb(new Error('数量最大值9999'))
            }
            cb()
          },
          trigger: 'blur'
        }
      ]
    }
  ]

  allocationDialog = false

  allocationForm: any = {
    agentId: '',
    allocationTime: ''
  }

  allocationColumn: any = [
    {
      label: '代理商',
      prop: 'agentId',
      slot: true,
      rules: [
        {
          required: true,
          message: '代理商不能为空',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '分配日期',
      prop: 'allocationTime',
      slot: true,
      rules: [
        {
          required: true,
          message: '分配日期不能为空',
          trigger: 'blur'
        }
      ]
    }
  ]

  collaboratingDialog = false

  collaboratingQuery: IDeviceArmDetailParams = {
    id: '',
    page: 1,
    limit: 10
  }

  collaboratingLoading = false

  collaboratingModelForm: any = {}

  collaboratingTableData: any = []

  collaboratingCrudOption: ICrudOption = {
    searchBox: false,
    menu: false,
    column: [
      {
        label: '业务合作方编号',
        prop: 'id',
        align: 'center'
      },
      {
        label: '业务合作方名称',
        prop: 'partnerName',
        align: 'center',
        overHidden: true
      },
      {
        label: '负责人',
        prop: 'personInCharge',
        align: 'center',
        overHidden: true
      },
      {
        label: '手机号',
        prop: 'phone',
        align: 'center',
      },
      {
        label: '业务合作方分成比例',
        prop: 'commission',
        align: 'center',
        slot: true
      }
    ]
  }

  collaboratingTableTotal = 0

  selectId: string = ''

  allocationLoading = false

  agentList: any = []

  selectRows: IDeviceArm[] = []

  get ids() {
    return this.selectRows.map(item => item.id)
  }

  getList () {
    this.tableLoading = true

    const query: IDeviceArmParams = deepCopy(this.queryParam)

    if (query.addTime && query.addTime.length === 2) {
      query.addStartTime = query.addTime[0]
      query.addEndTime = query.addTime[1]
    } else {
      query.addStartTime = ''
      query.addEndTime = ''
    }
    delete query.addTime

    if (query.allocationTime && query.allocationTime.length === 2) {
      query.allocationStartTime = query.allocationTime[0]
      query.allocationEndTime = query.allocationTime[1]
    } else {
      query.allocationStartTime = ''
      query.allocationEndTime = ''
    }
    delete query.allocationTime

    if (query.activateTime && query.activateTime.length === 2) {
      query.activateStartTime = query.activateTime[0]
      query.activateEndTime = query.activateTime[1]
    } else {
      query.activateStartTime = ''
      query.activateEndTime = ''
    }
    delete query.activateTime

    deviceArmQueryApi(query).then(e => {
      if (e && e.data) {
        this.tableLoading = false
        this.tableTotal = e.data.total
        this.tableData = e.data.list.map(item => {
          return {
            ...item,
            '_switchgear': (item.status === 1 || item.status === 2 || item.status === 3) ? 1 : 2
          }
        })
        setTimeout(() => {
          this.$nextTick(() => {
            for (let i = 0; i < e.data.list.length; i++) {
              const dom = document.querySelector(`#qrcode-${i}`)
              dom!.innerHTML = '';
              new Qrcode(dom, {
                text: WEB_HOST + '/device/index.html?d=' + e.data.list[i]['deviceSn'],
                width: 100,
                height: 100
              })
            }
          })
        }, 0)
      }
    })
  }

  switchStatus (id: string) {
    deviceArmModifyStatusApi(id).then(e => {
      if (e && e.data) {
        this.$message.success("操作成功!")
        this.getList()
      }
    })
  }

  openAdd () {
    this.addDialog = true
  }

  async rowSave (done: Function, loading: Function) {
    // @ts-ignore
    const valid = await this.$refs.addRef.validForm()
    if (valid) {
      deviceArmCreateApi(this.addForm.deviceNum).then(e => {
        if (e && e.data) {
          this.getList()
          this.$message.success("操作成功!")
          done()
        }
      }).finally(() => {
        loading()
      })
    } else {
      loading()
    }
  }

  rowClose () {
    this.addForm = {}
  }

  openAllocationDialog (id?: string) {
    if (id) {
      this.selectId = id
    } else {
      if (!this.ids.length) {
        this.$message.warning("请选择数据")
        return
      }
    }
    this.agentList = []
    this.queryAgent()
    this.allocationDialog = true
  }

  async allocationSave (done: Function, loading: Function) {
    // @ts-ignore
    const valid = await this.$refs.allocationRef.validForm()
    if (valid) {
      let arr: IDeviceArmAllocation[] = []
      if (this.selectId) {
        arr = [
          {
            id: this.selectId,
            agentId: this.allocationForm.agentId,
            allocationTime: this.allocationForm.allocationTime
          }
        ]
      } else {
        arr = this.ids.map(item => {
          return {
            id: item,
            agentId: this.allocationForm.agentId,
            allocationTime: this.allocationForm.allocationTime
          }
        })
      }
      deviceArmAllocationApi(arr).then(e => {
        if (e && e.data) {
          this.$message.success("操作成功!")
          this.getList()
          done()
        }
      }).finally(() => {
        loading()
      })
    } else {
      loading()
    }
  }

  allocationClose () {
    this.selectId = ''
    this.agentList = []
    this.allocationForm = {
      agentId: '',
      allocationTime: ''
    }
  }

  queryAgent(keyword?: string): Promise<IAgent[]> {
    return new Promise(resolve => {
      agentListApi(keyword).then(e => {
        if (e && e.data) {
          this.agentList = e.data.map((item) => {
            return {
              label: `${item.id}-${item.agentName}`,
              value: item.id
            }
          })
          resolve(e.data)
        }
      })
    })
  }

  async remoteMethod (query?: string) {
    this.allocationLoading = true
    await this.queryAgent(query)
    this.allocationLoading = false
  }

  queryCollaboratingList() {
    return new Promise(resolve => {
      this.collaboratingLoading = true
      deviceArmDetailListApi(this.collaboratingQuery).then(e => {
        if (e && e.data) {
          this.collaboratingTableData = e.data.list
          this.collaboratingTableTotal = e.data.total
          this.collaboratingLoading = false
          resolve(e.data)
        }
      })
    })
  }

  openCollaboratingDetail (row: IDeviceArm) {
    this.collaboratingQuery.id = row.id
    this.collaboratingQuery.page = 1
    this.collaboratingQuery.limit = 10
    this.queryCollaboratingList()
    this.collaboratingDialog = true
  }

  sortChange (e: any) {
    if (e == 'descending') {
      this.queryParam.allocationTimeSort = 2
    } else if (e === 'ascending') {
      this.queryParam.allocationTimeSort = 1
    } else {
      this.queryParam.allocationTimeSort = null
    }
    this.getList()
  }

  exportExcel () {
    const query: IDeviceArmParams = deepCopy(this.queryParam)

    if (query.addTime && query.addTime.length === 2) {
      query.addStartTime = query.addTime[0]
      query.addEndTime = query.addTime[1]
    } else {
      query.addStartTime = ''
      query.addEndTime = ''
    }
    delete query.addTime

    if (query.allocationTime && query.allocationTime.length === 2) {
      query.allocationStartTime = query.allocationTime[0]
      query.allocationEndTime = query.allocationTime[1]
    } else {
      query.allocationStartTime = ''
      query.allocationEndTime = ''
    }
    delete query.allocationTime

    if (query.activateTime && query.activateTime.length === 2) {
      query.activateStartTime = query.activateTime[0]
      query.activateEndTime = query.activateTime[1]
    } else {
      query.activateStartTime = ''
      query.activateEndTime = ''
    }
    delete query.activateTime

    deviceArmExportApi(query).then(e => {
      if (e && e.data) {
        exportFile(e.data, '康复设备信息.xlsx')
        this.$message.success('导出成功!')
      }
    })
  }

  created () {
    this.getList()
  }
}
